import { useEffect, useMemo, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getCourseConfig, getGradeFormula, getSubmission } from "../api/crud";
import { useDispatch } from "react-redux";
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ErrorIcon,
  HourGlass,
  InfoIcon,
  LockIcon,
  PendingIcon,
} from "../styles/icons";

import loadable from "@loadable/component";
import { CONTENT_LOADER_DEFAULT_PROPS, GT_THEME } from "../styles/Styles";
import { defaultFormulaForStudent } from "./problem-set/grading-formula/GradingFormulaDocs";
import Markdown from "./markdown/Markdown";
import ContentLoader from "react-content-loader";
import IconButton from "./IconButton";
import { useSelector } from "react-redux";

const SensitiveText = loadable(() => import("./SensitiveText"));
const BasicModal = loadable(() => import("./BasicModal"));

const TEXT = require("../text.json");

const ProblemSetGrade = ({
  problemSetId,
  courseId = null,
  courseConfig = null,
  noGradePlaceholder = null,
  cacheConfig = {},
  gradeProps = {},
  gradeInfoIconProps = {},
  pastDueIconProps = {},
  submissionClosedIconProps = {},
  notReleasedYetIconProps = {},
  noSubmissionsIconProps = {},
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [grade, setGrade] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formulaLoading, setFormulaLoading] = useState(true);
  const courseConfigRef = useRef(null);
  const gradeFormulaRef = useRef(null);

  // @ts-ignore
  const userInfo = useSelector((state) => state.user.userInfo);

  // Function to fetch the latest grade
  const fetchLastGrade = async () => {
    const token = await getAccessTokenSilently();

    const results = await getSubmission({
      params: {
        owner_id: userInfo?.userId,
        problem_set_id: problemSetId,
        group_id:
          courseId ||
          (courseConfigRef.current
            ? courseConfigRef.current.group_id
            : courseConfig?.group_id),
        sort_by: "calc_score",
        sort_order: "de",
        status: "completed",
        limit: 1,
      },
      authToken: token,
      dispatch: dispatch,
      cacheConfig: cacheConfig,
    });

    if (results.length && results[0].calculated_scores?.length) {
      const scores = results[0].calculated_scores;
      setGrade(scores[scores.length - 1]);
    }
  };

  useEffect(() => {
    if (!courseId && !courseConfig) {
      console.error("Either the courseId or courseConfig must be specified.");
      setLoading(false);
      return;
    }

    if (grade) return;

    setLoading(true);

    // Populate the base information
    getAccessTokenSilently().then((token) => {
      const loadFormula = (formulaId) => {
        setFormulaLoading(true);
        getGradeFormula({
          params: { resource_id: formulaId },
          authToken: token,
          dispatch: dispatch,
          cacheConfig: cacheConfig,
        }).then((results) => {
          if (results.length) {
            gradeFormulaRef.current = results[0];
          }
          setFormulaLoading(false);
        });
      };

      const promises = [];

      promises.push(fetchLastGrade());

      if (!courseConfig) {
        promises.push(
          getCourseConfig({
            params: {
              problem_set_id: problemSetId,
              group_id: courseId,
            },
            authToken: token,
            dispatch: dispatch,
            cacheConfig: cacheConfig,
          }).then((courseConfig) => {
            courseConfigRef.current = courseConfig;
            if (courseConfig.grade_formula_id) {
              loadFormula(courseConfig.grade_formula_id);
            } else {
              setFormulaLoading(false);
            }
          })
        );
      } else {
        courseConfigRef.current = courseConfig;
        if (courseConfig.grade_formula_id) {
          loadFormula(courseConfig.grade_formula_id);
        } else {
          setFormulaLoading(false);
        }
      }

      Promise.all(promises).then(() => {
        setLoading(false);
      });
    });

    // Poll for the latest grade updates every 60 sec
    // TODO: replace this with an event-driven flow

    // Set up polling
    const intervalId = setInterval(() => {
      fetchLastGrade();
    }, 45 * 1000); // Polling every 45 seconds

    // Cleanup: Stop polling when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseConfig, courseId, problemSetId]);

  const gradeFormulaDescriptionModal = useMemo(() => {
    let modalContent;

    if (formulaLoading) {
      modalContent = (
        <ContentLoader
          style={{ width: "100%", height: "300px" }}
          {...CONTENT_LOADER_DEFAULT_PROPS}
        >
          <rect x="0" y="0" rx="3" ry="3" width="100%" height="300px" />
        </ContentLoader>
      );
    } else {
      let description = defaultFormulaForStudent;

      if (
        gradeFormulaRef.current &&
        gradeFormulaRef.current?.descriptions &&
        gradeFormulaRef.current?.descriptions[0]?.text
      ) {
        // There's a formula with a description
        description = gradeFormulaRef.current.descriptions[0].text;
      } else if (
        gradeFormulaRef.current &&
        (!gradeFormulaRef.current?.descriptions?.length ||
          !gradeFormulaRef.current?.descriptions[0]?.text)
      ) {
        // There's a formula but no description
        description =
          TEXT.components.problem_set_grade
            .grade_formula_empty_description_placeholder;
      }

      modalContent = <Markdown content={description} />;
    }

    return (
      <BasicModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        title={TEXT.components.problem_set_grade.grade_formula_modal_title}
        sx={{ maxHeight: "470px", maxWidth: "500px" }}
      >
        {modalContent}
      </BasicModal>
    );
  }, [formulaLoading, modalOpen]);

  const infoElement = useMemo(() => {
    const isLate =
      courseConfigRef.current?.due &&
      new Date(courseConfigRef.current?.due).getTime() < Date.now();

    const afterUntil =
      courseConfigRef.current?.until &&
      new Date(courseConfigRef.current?.until).getTime() < Date.now();

    const beforeRelease =
      courseConfigRef.current?.release &&
      new Date(courseConfigRef.current?.release).getTime() > Date.now();

    let element;
    let tooltip;

    if (loading) {
      tooltip = "Loading grade information...";
      // @ts-ignore
      element = <CircularProgress color="primaryDarkBlue" size={"1.1rem"} />;
    } else if (grade) {
      if (grade?.error) {
        tooltip = grade.error_reason;
        element = (
          <ErrorIcon
            color={GT_THEME.palette.error.dark}
            size={"1.5rem"}
            {...pastDueIconProps}
          />
        );
      } else {
        const isGraderOverridden =
          grade.override_score !== null && grade.override_score !== undefined;
        const outputGrade = +(
          (isGraderOverridden ? grade.override_score : grade.computed_score) *
          100
        ).toFixed(2);
        tooltip = `Your grade: ${outputGrade}%`;
        element = (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Tooltip
              title={
                isGraderOverridden
                  ? TEXT.components.problem_set_grade.grade_override_message
                  : ""
              }
              describeChild
              arrow
            >
              <Box>
                <Typography
                  sx={{
                    textDecoration: isGraderOverridden
                      ? "underline"
                      : "inherit",
                  }}
                  {...gradeProps}
                >
                  <SensitiveText text={outputGrade + "%"} />
                </Typography>
              </Box>
            </Tooltip>
            <IconButton
              icon={InfoIcon}
              tooltip={TEXT.components.problem_set_grade.grade_formula_tooltip}
              size={"1.4rem"}
              {...gradeInfoIconProps}
              onClick={(e) => {
                setModalOpen(true);
                e.stopPropagation();
              }}
            />
          </Stack>
        );
      }
    } else if (isLate && !afterUntil) {
      tooltip = "The assignment is past due.";
      element = (
        <ErrorIcon
          color={GT_THEME.palette.warning.dark}
          size={"1.5rem"}
          {...pastDueIconProps}
        />
      );
    } else if (afterUntil) {
      tooltip = "The submission deadline has passed.";
      element = <LockIcon size={"1.4rem"} {...submissionClosedIconProps} />;
    } else if (beforeRelease) {
      tooltip = "The assignment is not released yet.";
      element = <PendingIcon size={"1.4rem"} {...notReleasedYetIconProps} />;
    } else {
      tooltip = "You have not made a submission yet";
      element = <HourGlass size={"1.4rem"} {...noSubmissionsIconProps} />;
    }

    return (
      <Tooltip title={tooltip} describeChild arrow>
        <Paper
          elevation={0}
          sx={{
            background: "inherit",
            minWidth: "50px",
            minHeight: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {element}
        </Paper>
      </Tooltip>
    );
  }, [
    grade,
    gradeInfoIconProps,
    gradeProps,
    loading,
    noSubmissionsIconProps,
    notReleasedYetIconProps,
    pastDueIconProps,
    submissionClosedIconProps,
  ]);

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {gradeFormulaDescriptionModal}
      {infoElement}
    </Box>
  );
};

export default ProblemSetGrade;
